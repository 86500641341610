import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["modal"];
  static values = { show: Boolean };

  private modalTarget: HTMLElement;
  private showValue: boolean;

  connect(): void {
    if (this.showValue) {
      $(this.modalTarget).modal();
    }
  }
}
