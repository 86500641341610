import Rails from "@rails/ujs";
import "core-js";
import "@slightlyoff/lite-vimeo";
import "whatwg-fetch";
import "@hotwired/turbo-rails";

require("blueimp-canvas-to-blob");
require("classlist-polyfill");
require("element-remove");
// expose jquery for all those small async rails snippets
global.$ = global.jQuery = require("jquery");

jQuery.event.special.touchstart = {
  setup: function (_, ns, handle) {
    this.addEventListener("touchstart", handle, { passive: false });
  },
};

jQuery.event.special.touchmove = {
  setup: function (_, ns, handle) {
    this.addEventListener("touchmove", handle, { passive: false });
  },
};

jQuery.event.special.wheel = {
  setup: function (_, ns, handle) {
    this.addEventListener("wheel", handle, { passive: true });
  },
};

jQuery.event.special.mousewheel = {
  setup: function (_, ns, handle) {
    this.addEventListener("mousewheel", handle, { passive: true });
  },
};

require("objectFitPolyfill");

window.objectFitPolyfill();

// packages
require("jquery-colorbox/jquery.colorbox");
require("jquery-colorbox/i18n/jquery.colorbox-de");

require("bootstrap/js/dist/scrollspy");
require("bootstrap/js/dist/modal");
require("bootstrap/js/dist/collapse");
require("bootstrap/js/dist/dropdown");
require("bootstrap/js/dist/tab");

// custom, require everything one-by-one for now
require("../javascripts/lib/image-preolader");
require("../javascripts/lib/scroll-to-me");
require("../javascripts/lib/range-slider");
require("../javascripts/lib/chip-checkbox");
require("../javascripts/job/proposal");
require("../javascripts/job/proposals");

require("../javascripts/bootstrap-components/_random");
require("../javascripts/bootstrap-components/cost-estimations");
require("../javascripts/bootstrap-components/global-notifications");
require("../javascripts/bootstrap-components/new-job");
require("../javascripts/bootstrap-components/show-hide-details");
require("../javascripts/bootstrap-components/verification-icon");
require("../javascripts/controllers");
require("./sentry");

Rails.start();
Turbo.session.drive = false;
Turbo.config.forms.mode = "optin";
