import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["modal", "amount", "same", "different"];

  private modalTarget: HTMLElement;

  private amountTarget: HTMLInputElement;

  private sameTarget: HTMLInputElement;

  private differentTarget: HTMLInputElement;

  private hasDifferentTarget: boolean;

  connect(): void {
    $(this.modalTarget).on("shown.bs.modal", () => {
      if (this.data.get("flat") === "true") {
        this.sameTarget.click();
      } else if (this.hasDifferentTarget) {
        this.differentTarget.click();
      } else {
        this.amountTarget.focus();
      }
    });
  }

  showModal(event): void {
    event.preventDefault();
    $(this.modalTarget).modal("show");
  }

  closeModal(event): void {
    event.preventDefault();
    $(this.modalTarget).modal("hide");
  }

  toggleAmount(event): void {
    if (event.target.value == "same") {
      this.amountTarget.value = this.data.get("amount");
    } else {
      this.amountTarget.focus();
    }
  }

  onAmountChange(): void {
    if (this.data.get("amount")) {
      if (this.amountTarget.value == this.data.get("amount")) {
        this.sameTarget.checked = true;
      } else {
        this.differentTarget.checked = true;
      }
    }
  }
}
