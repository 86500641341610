import { Controller } from "stimulus";

export default class extends Controller {
  connect(): void {
    $(this.element).modal(this.options);
  }

  get options(): any {
    return JSON.parse(this.data.get("options"));
  }
}
