import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["navigation"];

  connect() {
    const body = $("body");
    body.scrollspy({ target: this.navigationTarget });

    // Dig into internals so the first nav element is highlighted
    // starting from the top of the page.
    //
    // We need to override .refresh as scrollspy recalculates
    // target positions when container height changed.
    var scrollSpy = body.data("bs.scrollspy");
    var originalRefresh = scrollSpy.refresh;
    scrollSpy.refresh = function () {
      originalRefresh.apply(scrollSpy, arguments);
      scrollSpy._offsets[0] = 0;
      scrollSpy._process();
    };
    scrollSpy._offsets[0] = 0;
    scrollSpy._process();
  }
}
